/*
 * Generated by PEG.js 0.10.0.
 *
 * http://pegjs.org/
 */
/* eslint-disable */

"use strict";

function peg$subclass(child, parent) {
  function ctor() { this.constructor = child; }
  ctor.prototype = parent.prototype;
  child.prototype = new ctor();
}

function peg$SyntaxError(message, expected, found, location) {
  this.message  = message;
  this.expected = expected;
  this.found    = found;
  this.location = location;
  this.name     = "SyntaxError";

  if (typeof Error.captureStackTrace === "function") {
    Error.captureStackTrace(this, peg$SyntaxError);
  }
}

peg$subclass(peg$SyntaxError, Error);

peg$SyntaxError.buildMessage = function(expected, found) {
  var DESCRIBE_EXPECTATION_FNS = {
    literal: function(expectation) {
      return "\"" + literalEscape(expectation.text) + "\"";
    },

    "class": function(expectation) {
      var escapedParts = "",
        i;

      for (i = 0; i < expectation.parts.length; i++) {
        escapedParts += expectation.parts[i] instanceof Array
          ? classEscape(expectation.parts[i][0]) + "-" + classEscape(expectation.parts[i][1])
          : classEscape(expectation.parts[i]);
      }

      return "[" + (expectation.inverted ? "^" : "") + escapedParts + "]";
    },

    any: function(expectation) {
      return "any character";
    },

    end: function(expectation) {
      return "end of input";
    },

    other: function(expectation) {
      return expectation.description;
    }
  };

  function hex(ch) {
    return ch.charCodeAt(0).toString(16).toUpperCase();
  }

  function literalEscape(s) {
    return s
      .replace(/\\/g, '\\\\')
      .replace(/"/g,  '\\"')
      .replace(/\0/g, '\\0')
      .replace(/\t/g, '\\t')
      .replace(/\n/g, '\\n')
      .replace(/\r/g, '\\r')
      .replace(/[\x00-\x0F]/g,          function(ch) { return '\\x0' + hex(ch); })
      .replace(/[\x10-\x1F\x7F-\x9F]/g, function(ch) { return '\\x'  + hex(ch); });
  }

  function classEscape(s) {
    return s
      .replace(/\\/g, '\\\\')
      .replace(/\]/g, '\\]')
      .replace(/\^/g, '\\^')
      .replace(/-/g,  '\\-')
      .replace(/\0/g, '\\0')
      .replace(/\t/g, '\\t')
      .replace(/\n/g, '\\n')
      .replace(/\r/g, '\\r')
      .replace(/[\x00-\x0F]/g,          function(ch) { return '\\x0' + hex(ch); })
      .replace(/[\x10-\x1F\x7F-\x9F]/g, function(ch) { return '\\x'  + hex(ch); });
  }

  function describeExpectation(expectation) {
    return DESCRIBE_EXPECTATION_FNS[expectation.type](expectation);
  }

  function describeExpected(expected) {
    var descriptions = new Array(expected.length),
      i, j;

    for (i = 0; i < expected.length; i++) {
      descriptions[i] = describeExpectation(expected[i]);
    }

    descriptions.sort();

    if (descriptions.length > 0) {
      for (i = 1, j = 1; i < descriptions.length; i++) {
        if (descriptions[i - 1] !== descriptions[i]) {
          descriptions[j] = descriptions[i];
          j++;
        }
      }
      descriptions.length = j;
    }

    switch (descriptions.length) {
      case 1:
        return descriptions[0];

      case 2:
        return descriptions[0] + " or " + descriptions[1];

      default:
        return descriptions.slice(0, -1).join(", ")
          + ", or "
          + descriptions[descriptions.length - 1];
    }
  }

  function describeFound(found) {
    return found ? "\"" + literalEscape(found) + "\"" : "end of input";
  }

  return "Expected " + describeExpected(expected) + " but " + describeFound(found) + " found.";
};

function peg$parse(input, options) {
  options = options !== void 0 ? options : {};

  var peg$FAILED = {},

    peg$startRuleFunctions = { start: peg$parsestart },
    peg$startRuleFunction  = peg$parsestart,

    peg$c0 = "de",
    peg$c1 = peg$literalExpectation("de", false),
    peg$c2 = "d'",
    peg$c3 = peg$literalExpectation("d'", false),
    peg$c4 = function(amount, unit, container, ingredient, type) {
      var result = {
        quantity: amount,
        container: container,
        name: ingredient,
        unit: unit,
        type: type
      };

      for(var i in result) {
        if(result[i] === null || result[i] === undefined) {
          delete result[i];
        }
      }

      return result;
    },
    peg$c5 = "(",
    peg$c6 = peg$literalExpectation("(", false),
    peg$c7 = ")",
    peg$c8 = peg$literalExpectation(")", false),
    peg$c9 = function(amount, unit) {
      return { quantity: amount, unit: unit };
    },
    peg$c10 = ",",
    peg$c11 = peg$literalExpectation(",", false),
    peg$c12 = function(phrase) {
      return phrase;
    },
    peg$c13 = " ",
    peg$c14 = peg$literalExpectation(" ", false),
    peg$c15 = /^[\t]/,
    peg$c16 = peg$classExpectation(["\t"], false, false),
    peg$c17 = function(letters) { return letters.join(''); },
    peg$c18 = /^[.]/,
    peg$c19 = peg$classExpectation(["."], false, false),
    peg$c20 = /^[\/]/,
    peg$c21 = peg$classExpectation(["/"], false, false),
    peg$c22 = /^[0-9]/,
    peg$c23 = peg$classExpectation([["0", "9"]], false, false),
    peg$c24 = function(digits) { return digits.join(''); },
    peg$c25 = /^[a-zA-Z0-9\xE0\xC0\xE2\xC2\xE4\xC4\xE8\xC8\xEA\xCA\xEB\xCB\xE9\xC9\xEE\xCE\xEF\xCF\xF4\xD4\xF6\xD6\xF9\xD9\xFC\xDC\xFB\xDB\xE7\xC7\-'\u2019.%]/,
    peg$c26 = peg$classExpectation([["a", "z"], ["A", "Z"], ["0", "9"], "\xE0", "\xC0", "\xE2", "\xC2", "\xE4", "\xC4", "\xE8", "\xC8", "\xEA", "\xCA", "\xEB", "\xCB", "\xE9", "\xC9", "\xEE", "\xCE", "\xEF", "\xCF", "\xF4", "\xD4", "\xF6", "\xD6", "\xF9", "\xD9", "\xFC", "\xDC", "\xFB", "\xDB", "\xE7", "\xC7", "-", "'", "\u2019", ".", "%"], false, false),
    peg$c27 = function(english_unit) { return english_unit; },
    peg$c28 = function(metric_unit) { return metric_unit; },
    peg$c29 = function(imprecise_unit) { return imprecise_unit; },
    peg$c30 = function() { return 'cup'; },
    peg$c31 = function() { return 'fluid_ounce'; },
    peg$c32 = function() { return 'gallon'; },
    peg$c33 = function() { return 'ounce'; },
    peg$c34 = function() { return 'pint'; },
    peg$c35 = function() { return 'pound'; },
    peg$c36 = function() { return 'quart'; },
    peg$c37 = function() { return 'tbsp'; },
    peg$c38 = function() { return 'tsp'; },
    peg$c39 = "cups",
    peg$c40 = peg$literalExpectation("cups", true),
    peg$c41 = "cup",
    peg$c42 = peg$literalExpectation("cup", true),
    peg$c43 = "tasses",
    peg$c44 = peg$literalExpectation("tasses", true),
    peg$c45 = "tasse",
    peg$c46 = peg$literalExpectation("tasse", true),
    peg$c47 = "fluid",
    peg$c48 = peg$literalExpectation("fluid", true),
    peg$c49 = "fl",
    peg$c50 = peg$literalExpectation("fl", true),
    peg$c51 = ".",
    peg$c52 = peg$literalExpectation(".", false),
    peg$c53 = "gallons",
    peg$c54 = peg$literalExpectation("gallons", true),
    peg$c55 = "gallon",
    peg$c56 = peg$literalExpectation("gallon", true),
    peg$c57 = "gal.",
    peg$c58 = peg$literalExpectation("gal.", true),
    peg$c59 = "gal",
    peg$c60 = peg$literalExpectation("gal", true),
    peg$c61 = "ounces",
    peg$c62 = peg$literalExpectation("ounces", true),
    peg$c63 = "ounce",
    peg$c64 = peg$literalExpectation("ounce", true),
    peg$c65 = "oz.",
    peg$c66 = peg$literalExpectation("oz.", true),
    peg$c67 = "oz",
    peg$c68 = peg$literalExpectation("oz", true),
    peg$c69 = "onces",
    peg$c70 = peg$literalExpectation("onces", true),
    peg$c71 = "once",
    peg$c72 = peg$literalExpectation("once", true),
    peg$c73 = "pints",
    peg$c74 = peg$literalExpectation("pints", true),
    peg$c75 = "pint",
    peg$c76 = peg$literalExpectation("pint", true),
    peg$c77 = "pt.",
    peg$c78 = peg$literalExpectation("pt.", true),
    peg$c79 = "pt",
    peg$c80 = peg$literalExpectation("pt", true),
    peg$c81 = "pounds",
    peg$c82 = peg$literalExpectation("pounds", true),
    peg$c83 = "pound",
    peg$c84 = peg$literalExpectation("pound", true),
    peg$c85 = "lbs.",
    peg$c86 = peg$literalExpectation("lbs.", true),
    peg$c87 = "lbs",
    peg$c88 = peg$literalExpectation("lbs", true),
    peg$c89 = "lb.",
    peg$c90 = peg$literalExpectation("lb.", true),
    peg$c91 = "lb",
    peg$c92 = peg$literalExpectation("lb", true),
    peg$c93 = "livres",
    peg$c94 = peg$literalExpectation("livres", true),
    peg$c95 = "livre",
    peg$c96 = peg$literalExpectation("livre", true),
    peg$c97 = "quarts",
    peg$c98 = peg$literalExpectation("quarts", true),
    peg$c99 = "quart",
    peg$c100 = peg$literalExpectation("quart", true),
    peg$c101 = "qts.",
    peg$c102 = peg$literalExpectation("qts.", true),
    peg$c103 = "qts",
    peg$c104 = peg$literalExpectation("qts", true),
    peg$c105 = "qt.",
    peg$c106 = peg$literalExpectation("qt.", true),
    peg$c107 = "qt",
    peg$c108 = peg$literalExpectation("qt", true),
    peg$c109 = "tablespoons",
    peg$c110 = peg$literalExpectation("tablespoons", true),
    peg$c111 = "tablespoon",
    peg$c112 = peg$literalExpectation("tablespoon", true),
    peg$c113 = "tbsp.",
    peg$c114 = peg$literalExpectation("tbsp.", true),
    peg$c115 = "tbsp",
    peg$c116 = peg$literalExpectation("tbsp", true),
    peg$c117 = "tbs.",
    peg$c118 = peg$literalExpectation("tbs.", true),
    peg$c119 = "tbs",
    peg$c120 = peg$literalExpectation("tbs", true),
    peg$c121 = "T.",
    peg$c122 = peg$literalExpectation("T.", false),
    peg$c123 = "T",
    peg$c124 = peg$literalExpectation("T", false),
    peg$c125 = "c. \xE0 s.",
    peg$c126 = peg$literalExpectation("c. \xE0 s.", false),
    peg$c127 = "c. \xE0 soupe",
    peg$c128 = peg$literalExpectation("c. \xE0 soupe", false),
    peg$c129 = "teaspoons",
    peg$c130 = peg$literalExpectation("teaspoons", true),
    peg$c131 = "teaspoon",
    peg$c132 = peg$literalExpectation("teaspoon", true),
    peg$c133 = "tsp.",
    peg$c134 = peg$literalExpectation("tsp.", true),
    peg$c135 = "tsp",
    peg$c136 = peg$literalExpectation("tsp", true),
    peg$c137 = "t.",
    peg$c138 = peg$literalExpectation("t.", false),
    peg$c139 = "t",
    peg$c140 = peg$literalExpectation("t", false),
    peg$c141 = "c. \xE0 c.",
    peg$c142 = peg$literalExpectation("c. \xE0 c.", true),
    peg$c143 = "c. \xE0 caf\xE9",
    peg$c144 = peg$literalExpectation("c. \xE0 caf\xE9", true),
    peg$c145 = "c. \xE0 th\xE9",
    peg$c146 = peg$literalExpectation("c. \xE0 th\xE9", true),
    peg$c147 = "c \xE0 th\xE9",
    peg$c148 = peg$literalExpectation("c \xE0 th\xE9", true),
    peg$c149 = "cuill\xE8res \xE0 caf\xE9",
    peg$c150 = peg$literalExpectation("cuill\xE8res \xE0 caf\xE9", true),
    peg$c151 = "cuill\xE8re \xE0 caf\xE9",
    peg$c152 = peg$literalExpectation("cuill\xE8re \xE0 caf\xE9", true),
    peg$c153 = "cuill\xE8res \xE0 th\xE9",
    peg$c154 = peg$literalExpectation("cuill\xE8res \xE0 th\xE9", true),
    peg$c155 = "cuill\xE8re \xE0 th\xE9",
    peg$c156 = peg$literalExpectation("cuill\xE8re \xE0 th\xE9", true),
    peg$c157 = function() { return 'g'; },
    peg$c158 = function() { return 'kg'; },
    peg$c159 = function() { return 'l'; },
    peg$c160 = function() { return 'dl'; },
    peg$c161 = function() { return 'cl'; },
    peg$c162 = function() { return 'mg'; },
    peg$c163 = function() { return 'ml'; },
    peg$c164 = "grams",
    peg$c165 = peg$literalExpectation("grams", true),
    peg$c166 = "grammes",
    peg$c167 = peg$literalExpectation("grammes", true),
    peg$c168 = "gramme",
    peg$c169 = peg$literalExpectation("gramme", true),
    peg$c170 = "gram",
    peg$c171 = peg$literalExpectation("gram", true),
    peg$c172 = "gr.",
    peg$c173 = peg$literalExpectation("gr.", true),
    peg$c174 = "gr",
    peg$c175 = peg$literalExpectation("gr", true),
    peg$c176 = "g.",
    peg$c177 = peg$literalExpectation("g.", true),
    peg$c178 = "g",
    peg$c179 = peg$literalExpectation("g", true),
    peg$c180 = "kilograms",
    peg$c181 = peg$literalExpectation("kilograms", true),
    peg$c182 = "kilogram",
    peg$c183 = peg$literalExpectation("kilogram", true),
    peg$c184 = "kg.",
    peg$c185 = peg$literalExpectation("kg.", true),
    peg$c186 = "kg",
    peg$c187 = peg$literalExpectation("kg", true),
    peg$c188 = "kilogrammes",
    peg$c189 = peg$literalExpectation("kilogrammes", true),
    peg$c190 = "kilogramme",
    peg$c191 = peg$literalExpectation("kilogramme", true),
    peg$c192 = "liters",
    peg$c193 = peg$literalExpectation("liters", true),
    peg$c194 = "liter",
    peg$c195 = peg$literalExpectation("liter", true),
    peg$c196 = "litres",
    peg$c197 = peg$literalExpectation("litres", true),
    peg$c198 = "litre",
    peg$c199 = peg$literalExpectation("litre", true),
    peg$c200 = "l.",
    peg$c201 = peg$literalExpectation("l.", true),
    peg$c202 = "l",
    peg$c203 = peg$literalExpectation("l", true),
    peg$c204 = "deciliters",
    peg$c205 = peg$literalExpectation("deciliters", true),
    peg$c206 = "deciliter",
    peg$c207 = peg$literalExpectation("deciliter", true),
    peg$c208 = "dl.",
    peg$c209 = peg$literalExpectation("dl.", true),
    peg$c210 = "dl",
    peg$c211 = peg$literalExpectation("dl", true),
    peg$c212 = "d\xE9cilitres",
    peg$c213 = peg$literalExpectation("d\xE9cilitres", true),
    peg$c214 = "d\xE9cilitre",
    peg$c215 = peg$literalExpectation("d\xE9cilitre", true),
    peg$c216 = "centiliters",
    peg$c217 = peg$literalExpectation("centiliters", true),
    peg$c218 = "centiliter",
    peg$c219 = peg$literalExpectation("centiliter", true),
    peg$c220 = "cl.",
    peg$c221 = peg$literalExpectation("cl.", true),
    peg$c222 = "cl",
    peg$c223 = peg$literalExpectation("cl", true),
    peg$c224 = "centilitres",
    peg$c225 = peg$literalExpectation("centilitres", true),
    peg$c226 = "centilitre",
    peg$c227 = peg$literalExpectation("centilitre", true),
    peg$c228 = "milligrams",
    peg$c229 = peg$literalExpectation("milligrams", true),
    peg$c230 = "milligram",
    peg$c231 = peg$literalExpectation("milligram", true),
    peg$c232 = "mg.",
    peg$c233 = peg$literalExpectation("mg.", true),
    peg$c234 = "mg",
    peg$c235 = peg$literalExpectation("mg", true),
    peg$c236 = "milligrammes",
    peg$c237 = peg$literalExpectation("milligrammes", true),
    peg$c238 = "milligramme",
    peg$c239 = peg$literalExpectation("milligramme", true),
    peg$c240 = "milliliters",
    peg$c241 = peg$literalExpectation("milliliters", true),
    peg$c242 = "milliliter",
    peg$c243 = peg$literalExpectation("milliliter", true),
    peg$c244 = "ml.",
    peg$c245 = peg$literalExpectation("ml.", true),
    peg$c246 = "ml",
    peg$c247 = peg$literalExpectation("ml", true),
    peg$c248 = "millilitres",
    peg$c249 = peg$literalExpectation("millilitres", true),
    peg$c250 = "millilitre",
    peg$c251 = peg$literalExpectation("millilitre", true),
    peg$c252 = function() { return 'handful'; },
    peg$c253 = function() { return 'pinch'; },
    peg$c254 = function() { return 'touch'; },
    peg$c255 = function() { return 'box'; },
    peg$c256 = function() { return 'tablet' },
    peg$c257 = function() { return 'bit' },
    peg$c258 = function() { return 'packet' },
    peg$c259 = "bo\xEEtes",
    peg$c260 = peg$literalExpectation("bo\xEEtes", true),
    peg$c261 = "bo\xEEte",
    peg$c262 = peg$literalExpectation("bo\xEEte", true),
    peg$c263 = "boites",
    peg$c264 = peg$literalExpectation("boites", true),
    peg$c265 = "boite",
    peg$c266 = peg$literalExpectation("boite", true),
    peg$c267 = "contenants",
    peg$c268 = peg$literalExpectation("contenants", true),
    peg$c269 = "contenant",
    peg$c270 = peg$literalExpectation("contenant", true),
    peg$c271 = "dashes",
    peg$c272 = peg$literalExpectation("dashes", true),
    peg$c273 = "dash",
    peg$c274 = peg$literalExpectation("dash", true),
    peg$c275 = "handfuls",
    peg$c276 = peg$literalExpectation("handfuls", true),
    peg$c277 = "handful",
    peg$c278 = peg$literalExpectation("handful", true),
    peg$c279 = "poign\xE9es",
    peg$c280 = peg$literalExpectation("poign\xE9es", true),
    peg$c281 = "poign\xE9e",
    peg$c282 = peg$literalExpectation("poign\xE9e", true),
    peg$c283 = "tablets",
    peg$c284 = peg$literalExpectation("tablets", true),
    peg$c285 = "tablettes",
    peg$c286 = peg$literalExpectation("tablettes", true),
    peg$c287 = "tablette",
    peg$c288 = peg$literalExpectation("tablette", true),
    peg$c289 = "tablet",
    peg$c290 = peg$literalExpectation("tablet", true),
    peg$c291 = "morceaux",
    peg$c292 = peg$literalExpectation("morceaux", true),
    peg$c293 = "morceau",
    peg$c294 = peg$literalExpectation("morceau", true),
    peg$c295 = "sachets",
    peg$c296 = peg$literalExpectation("sachets", true),
    peg$c297 = "sachet",
    peg$c298 = peg$literalExpectation("sachet", true),
    peg$c299 = "pinches",
    peg$c300 = peg$literalExpectation("pinches", true),
    peg$c301 = "pinch",
    peg$c302 = peg$literalExpectation("pinch", true),
    peg$c303 = "pinc\xE9es",
    peg$c304 = peg$literalExpectation("pinc\xE9es", true),
    peg$c305 = "pinc\xE9e",
    peg$c306 = peg$literalExpectation("pinc\xE9e", true),
    peg$c307 = "touches",
    peg$c308 = peg$literalExpectation("touches", true),
    peg$c309 = "touch",
    peg$c310 = peg$literalExpectation("touch", true),

    peg$currPos          = 0,
    peg$savedPos         = 0,
    peg$posDetailsCache  = [{ line: 1, column: 1 }],
    peg$maxFailPos       = 0,
    peg$maxFailExpected  = [],
    peg$silentFails      = 0,

    peg$result;

  if ("startRule" in options) {
    if (!(options.startRule in peg$startRuleFunctions)) {
      throw new Error("Can't start parsing from rule \"" + options.startRule + "\".");
    }

    peg$startRuleFunction = peg$startRuleFunctions[options.startRule];
  }

  function text() {
    return input.substring(peg$savedPos, peg$currPos);
  }

  function location() {
    return peg$computeLocation(peg$savedPos, peg$currPos);
  }

  function expected(description, location) {
    location = location !== void 0 ? location : peg$computeLocation(peg$savedPos, peg$currPos)

    throw peg$buildStructuredError(
      [peg$otherExpectation(description)],
      input.substring(peg$savedPos, peg$currPos),
      location
    );
  }

  function error(message, location) {
    location = location !== void 0 ? location : peg$computeLocation(peg$savedPos, peg$currPos)

    throw peg$buildSimpleError(message, location);
  }

  function peg$literalExpectation(text, ignoreCase) {
    return { type: "literal", text: text, ignoreCase: ignoreCase };
  }

  function peg$classExpectation(parts, inverted, ignoreCase) {
    return { type: "class", parts: parts, inverted: inverted, ignoreCase: ignoreCase };
  }

  function peg$anyExpectation() {
    return { type: "any" };
  }

  function peg$endExpectation() {
    return { type: "end" };
  }

  function peg$otherExpectation(description) {
    return { type: "other", description: description };
  }

  function peg$computePosDetails(pos) {
    var details = peg$posDetailsCache[pos], p;

    if (details) {
      return details;
    } else {
      p = pos - 1;
      while (!peg$posDetailsCache[p]) {
        p--;
      }

      details = peg$posDetailsCache[p];
      details = {
        line:   details.line,
        column: details.column
      };

      while (p < pos) {
        if (input.charCodeAt(p) === 10) {
          details.line++;
          details.column = 1;
        } else {
          details.column++;
        }

        p++;
      }

      peg$posDetailsCache[pos] = details;
      return details;
    }
  }

  function peg$computeLocation(startPos, endPos) {
    var startPosDetails = peg$computePosDetails(startPos),
      endPosDetails   = peg$computePosDetails(endPos);

    return {
      start: {
        offset: startPos,
        line:   startPosDetails.line,
        column: startPosDetails.column
      },
      end: {
        offset: endPos,
        line:   endPosDetails.line,
        column: endPosDetails.column
      }
    };
  }

  function peg$fail(expected) {
    if (peg$currPos < peg$maxFailPos) { return; }

    if (peg$currPos > peg$maxFailPos) {
      peg$maxFailPos = peg$currPos;
      peg$maxFailExpected = [];
    }

    peg$maxFailExpected.push(expected);
  }

  function peg$buildSimpleError(message, location) {
    return new peg$SyntaxError(message, null, null, location);
  }

  function peg$buildStructuredError(expected, found, location) {
    return new peg$SyntaxError(
      peg$SyntaxError.buildMessage(expected, found),
      expected,
      found,
      location
    );
  }

  function peg$parsestart() {
    var s0;

    s0 = peg$parseingredient_addition();

    return s0;
  }

  function peg$parseingredient_additions() {
    var s0, s1;

    s0 = [];
    s1 = peg$parseingredient_addition();
    if (s1 !== peg$FAILED) {
      while (s1 !== peg$FAILED) {
        s0.push(s1);
        s1 = peg$parseingredient_addition();
      }
    } else {
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseingredient_addition() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11;

    s0 = peg$currPos;
    s1 = peg$parseamount();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$parsews();
      if (s3 !== peg$FAILED) {
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$parsews();
        }
      } else {
        s2 = peg$FAILED;
      }
      if (s2 === peg$FAILED) {
        s2 = null;
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$currPos;
        if (input.substr(peg$currPos, 2) === peg$c0) {
          s4 = peg$c0;
          peg$currPos += 2;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c1); }
        }
        if (s4 !== peg$FAILED) {
          s5 = [];
          s6 = peg$parsews();
          if (s6 !== peg$FAILED) {
            while (s6 !== peg$FAILED) {
              s5.push(s6);
              s6 = peg$parsews();
            }
          } else {
            s5 = peg$FAILED;
          }
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        if (s3 === peg$FAILED) {
          s3 = null;
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parseunit();
          if (s4 === peg$FAILED) {
            s4 = null;
          }
          if (s4 !== peg$FAILED) {
            s5 = [];
            s6 = peg$parsews();
            if (s6 !== peg$FAILED) {
              while (s6 !== peg$FAILED) {
                s5.push(s6);
                s6 = peg$parsews();
              }
            } else {
              s5 = peg$FAILED;
            }
            if (s5 === peg$FAILED) {
              s5 = null;
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parsecontainer();
              if (s6 === peg$FAILED) {
                s6 = null;
              }
              if (s6 !== peg$FAILED) {
                s7 = [];
                s8 = peg$parsews();
                if (s8 !== peg$FAILED) {
                  while (s8 !== peg$FAILED) {
                    s7.push(s8);
                    s8 = peg$parsews();
                  }
                } else {
                  s7 = peg$FAILED;
                }
                if (s7 === peg$FAILED) {
                  s7 = null;
                }
                if (s7 !== peg$FAILED) {
                  s8 = peg$currPos;
                  if (input.substr(peg$currPos, 2) === peg$c0) {
                    s9 = peg$c0;
                    peg$currPos += 2;
                  } else {
                    s9 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c1); }
                  }
                  if (s9 !== peg$FAILED) {
                    s10 = [];
                    s11 = peg$parsews();
                    if (s11 !== peg$FAILED) {
                      while (s11 !== peg$FAILED) {
                        s10.push(s11);
                        s11 = peg$parsews();
                      }
                    } else {
                      s10 = peg$FAILED;
                    }
                    if (s10 !== peg$FAILED) {
                      s9 = [s9, s10];
                      s8 = s9;
                    } else {
                      peg$currPos = s8;
                      s8 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s8;
                    s8 = peg$FAILED;
                  }
                  if (s8 === peg$FAILED) {
                    if (input.substr(peg$currPos, 2) === peg$c2) {
                      s8 = peg$c2;
                      peg$currPos += 2;
                    } else {
                      s8 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c3); }
                    }
                  }
                  if (s8 === peg$FAILED) {
                    s8 = null;
                  }
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parsephrase();
                    if (s9 === peg$FAILED) {
                      s9 = null;
                    }
                    if (s9 !== peg$FAILED) {
                      s10 = peg$parsetype();
                      if (s10 === peg$FAILED) {
                        s10 = null;
                      }
                      if (s10 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c4(s1, s4, s6, s9, s10);
                        s0 = s1;
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseamount() {
    var s0;

    s0 = peg$parsefloat();
    if (s0 === peg$FAILED) {
      s0 = peg$parsemixed_number();
      if (s0 === peg$FAILED) {
        s0 = peg$parsefraction();
        if (s0 === peg$FAILED) {
          s0 = peg$parseinteger();
        }
      }
    }

    return s0;
  }

  function peg$parsecontainer() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 40) {
      s1 = peg$c5;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c6); }
    }
    if (s1 === peg$FAILED) {
      s1 = null;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseamount();
      if (s2 !== peg$FAILED) {
        s3 = [];
        s4 = peg$parsews();
        if (s4 !== peg$FAILED) {
          while (s4 !== peg$FAILED) {
            s3.push(s4);
            s4 = peg$parsews();
          }
        } else {
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parseunit();
          if (s4 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 41) {
              s5 = peg$c7;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c8); }
            }
            if (s5 === peg$FAILED) {
              s5 = null;
            }
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c9(s2, s4);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsetype() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 44) {
      s1 = peg$c10;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c11); }
    }
    if (s1 === peg$FAILED) {
      s1 = null;
    }
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$parsews();
      if (s3 !== peg$FAILED) {
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$parsews();
        }
      } else {
        s2 = peg$FAILED;
      }
      if (s2 === peg$FAILED) {
        s2 = null;
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parsephrase();
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c12(s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsews() {
    var s0;

    if (input.charCodeAt(peg$currPos) === 32) {
      s0 = peg$c13;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c14); }
    }
    if (s0 === peg$FAILED) {
      if (peg$c15.test(input.charAt(peg$currPos))) {
        s0 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c16); }
      }
    }

    return s0;
  }

  function peg$parsespace() {
    var s0;

    if (input.charCodeAt(peg$currPos) === 32) {
      s0 = peg$c13;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c14); }
    }

    return s0;
  }

  function peg$parsephrase() {
    var s0, s1, s2, s3, s4, s5, s6;

    s0 = peg$currPos;
    s1 = peg$currPos;
    s2 = peg$parseword();
    if (s2 !== peg$FAILED) {
      s3 = [];
      s4 = peg$currPos;
      s5 = peg$parsespace();
      if (s5 !== peg$FAILED) {
        s6 = peg$parseword();
        if (s6 !== peg$FAILED) {
          s5 = [s5, s6];
          s4 = s5;
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
      } else {
        peg$currPos = s4;
        s4 = peg$FAILED;
      }
      while (s4 !== peg$FAILED) {
        s3.push(s4);
        s4 = peg$currPos;
        s5 = peg$parsespace();
        if (s5 !== peg$FAILED) {
          s6 = peg$parseword();
          if (s6 !== peg$FAILED) {
            s5 = [s5, s6];
            s4 = s5;
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
      }
      if (s3 !== peg$FAILED) {
        s2 = [s2, s3];
        s1 = s2;
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s0 = input.substring(s0, peg$currPos);
    } else {
      s0 = s1;
    }

    return s0;
  }

  function peg$parseword() {
    var s0, s1, s2;

    s0 = peg$currPos;
    s1 = [];
    s2 = peg$parseletter();
    if (s2 !== peg$FAILED) {
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        s2 = peg$parseletter();
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c17(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parsefloat() {
    var s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    s1 = peg$currPos;
    s2 = peg$parseinteger();
    if (s2 === peg$FAILED) {
      s2 = null;
    }
    if (s2 !== peg$FAILED) {
      if (peg$c18.test(input.charAt(peg$currPos))) {
        s3 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c19); }
      }
      if (s3 !== peg$FAILED) {
        s4 = peg$parseinteger();
        if (s4 !== peg$FAILED) {
          s2 = [s2, s3, s4];
          s1 = s2;
        } else {
          peg$currPos = s1;
          s1 = peg$FAILED;
        }
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s0 = input.substring(s0, peg$currPos);
    } else {
      s0 = s1;
    }

    return s0;
  }

  function peg$parsemixed_number() {
    var s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    s1 = peg$currPos;
    s2 = peg$parseinteger();
    if (s2 !== peg$FAILED) {
      s3 = peg$parsespace();
      if (s3 !== peg$FAILED) {
        s4 = peg$parsefraction();
        if (s4 !== peg$FAILED) {
          s2 = [s2, s3, s4];
          s1 = s2;
        } else {
          peg$currPos = s1;
          s1 = peg$FAILED;
        }
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s0 = input.substring(s0, peg$currPos);
    } else {
      s0 = s1;
    }

    return s0;
  }

  function peg$parsefraction() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$currPos;
    s2 = peg$currPos;
    s3 = peg$parseinteger();
    if (s3 !== peg$FAILED) {
      s4 = [];
      s5 = peg$parsews();
      if (s5 !== peg$FAILED) {
        while (s5 !== peg$FAILED) {
          s4.push(s5);
          s5 = peg$parsews();
        }
      } else {
        s4 = peg$FAILED;
      }
      if (s4 !== peg$FAILED) {
        s3 = [s3, s4];
        s2 = s3;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
    } else {
      peg$currPos = s2;
      s2 = peg$FAILED;
    }
    if (s2 === peg$FAILED) {
      s2 = null;
    }
    if (s2 !== peg$FAILED) {
      s3 = peg$parseinteger();
      if (s3 !== peg$FAILED) {
        if (peg$c20.test(input.charAt(peg$currPos))) {
          s4 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c21); }
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parseinteger();
          if (s5 !== peg$FAILED) {
            s2 = [s2, s3, s4, s5];
            s1 = s2;
          } else {
            peg$currPos = s1;
            s1 = peg$FAILED;
          }
        } else {
          peg$currPos = s1;
          s1 = peg$FAILED;
        }
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s0 = input.substring(s0, peg$currPos);
    } else {
      s0 = s1;
    }

    return s0;
  }

  function peg$parseinteger() {
    var s0, s1, s2;

    s0 = peg$currPos;
    s1 = [];
    if (peg$c22.test(input.charAt(peg$currPos))) {
      s2 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s2 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c23); }
    }
    if (s2 !== peg$FAILED) {
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        if (peg$c22.test(input.charAt(peg$currPos))) {
          s2 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c23); }
        }
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c24(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseletter() {
    var s0;

    if (peg$c25.test(input.charAt(peg$currPos))) {
      s0 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c26); }
    }

    return s0;
  }

  function peg$parseunit() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parseenglish_unit();
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$silentFails++;
      s3 = peg$parseword();
      peg$silentFails--;
      if (s3 === peg$FAILED) {
        s2 = void 0;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c27(s1);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parsemetric_unit();
      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        peg$silentFails++;
        s3 = peg$parseword();
        peg$silentFails--;
        if (s3 === peg$FAILED) {
          s2 = void 0;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c28(s1);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parseimprecise_unit();
        if (s1 !== peg$FAILED) {
          s2 = peg$currPos;
          peg$silentFails++;
          s3 = peg$parseword();
          peg$silentFails--;
          if (s3 === peg$FAILED) {
            s2 = void 0;
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
          if (s2 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c29(s1);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      }
    }

    return s0;
  }

  function peg$parseenglish_unit() {
    var s0, s1;

    s0 = peg$currPos;
    s1 = peg$parsecup();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c30();
    }
    s0 = s1;
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parsefluid_ounce();
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c31();
      }
      s0 = s1;
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parsegallon();
        if (s1 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c32();
        }
        s0 = s1;
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          s1 = peg$parseounce();
          if (s1 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c33();
          }
          s0 = s1;
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            s1 = peg$parsepint();
            if (s1 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c34();
            }
            s0 = s1;
            if (s0 === peg$FAILED) {
              s0 = peg$currPos;
              s1 = peg$parsepound();
              if (s1 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c35();
              }
              s0 = s1;
              if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = peg$parsequart();
                if (s1 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c36();
                }
                s0 = s1;
                if (s0 === peg$FAILED) {
                  s0 = peg$currPos;
                  s1 = peg$parsetablespoon();
                  if (s1 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c37();
                  }
                  s0 = s1;
                  if (s0 === peg$FAILED) {
                    s0 = peg$currPos;
                    s1 = peg$parseteaspoon();
                    if (s1 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c38();
                    }
                    s0 = s1;
                  }
                }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parsecup() {
    var s0;

    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c39) {
      s0 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c40); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 3).toLowerCase() === peg$c41) {
        s0 = input.substr(peg$currPos, 3);
        peg$currPos += 3;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c42); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 6).toLowerCase() === peg$c43) {
          s0 = input.substr(peg$currPos, 6);
          peg$currPos += 6;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c44); }
        }
        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 5).toLowerCase() === peg$c45) {
            s0 = input.substr(peg$currPos, 5);
            peg$currPos += 5;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c46); }
          }
        }
      }
    }

    return s0;
  }

  function peg$parsefluid_ounce() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parsefluid();
    if (s1 !== peg$FAILED) {
      s2 = peg$parsews();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseounce();
        if (s3 !== peg$FAILED) {
          s1 = [s1, s2, s3];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsefluid() {
    var s0, s1, s2;

    if (input.substr(peg$currPos, 5).toLowerCase() === peg$c47) {
      s0 = input.substr(peg$currPos, 5);
      peg$currPos += 5;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c48); }
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 2).toLowerCase() === peg$c49) {
        s1 = input.substr(peg$currPos, 2);
        peg$currPos += 2;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c50); }
      }
      if (s1 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 46) {
          s2 = peg$c51;
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c52); }
        }
        if (s2 === peg$FAILED) {
          s2 = null;
        }
        if (s2 !== peg$FAILED) {
          s1 = [s1, s2];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parsegallon() {
    var s0;

    if (input.substr(peg$currPos, 7).toLowerCase() === peg$c53) {
      s0 = input.substr(peg$currPos, 7);
      peg$currPos += 7;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c54); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 6).toLowerCase() === peg$c55) {
        s0 = input.substr(peg$currPos, 6);
        peg$currPos += 6;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c56); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 4).toLowerCase() === peg$c57) {
          s0 = input.substr(peg$currPos, 4);
          peg$currPos += 4;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c58); }
        }
        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 3).toLowerCase() === peg$c59) {
            s0 = input.substr(peg$currPos, 3);
            peg$currPos += 3;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c60); }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseounce() {
    var s0;

    if (input.substr(peg$currPos, 6).toLowerCase() === peg$c61) {
      s0 = input.substr(peg$currPos, 6);
      peg$currPos += 6;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c62); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 5).toLowerCase() === peg$c63) {
        s0 = input.substr(peg$currPos, 5);
        peg$currPos += 5;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c64); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 3).toLowerCase() === peg$c65) {
          s0 = input.substr(peg$currPos, 3);
          peg$currPos += 3;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c66); }
        }
        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 2).toLowerCase() === peg$c67) {
            s0 = input.substr(peg$currPos, 2);
            peg$currPos += 2;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c68); }
          }
          if (s0 === peg$FAILED) {
            if (input.substr(peg$currPos, 5).toLowerCase() === peg$c69) {
              s0 = input.substr(peg$currPos, 5);
              peg$currPos += 5;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c70); }
            }
            if (s0 === peg$FAILED) {
              if (input.substr(peg$currPos, 4).toLowerCase() === peg$c71) {
                s0 = input.substr(peg$currPos, 4);
                peg$currPos += 4;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c72); }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parsepint() {
    var s0;

    if (input.substr(peg$currPos, 5).toLowerCase() === peg$c73) {
      s0 = input.substr(peg$currPos, 5);
      peg$currPos += 5;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c74); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 4).toLowerCase() === peg$c75) {
        s0 = input.substr(peg$currPos, 4);
        peg$currPos += 4;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c76); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 3).toLowerCase() === peg$c77) {
          s0 = input.substr(peg$currPos, 3);
          peg$currPos += 3;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c78); }
        }
        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 2).toLowerCase() === peg$c79) {
            s0 = input.substr(peg$currPos, 2);
            peg$currPos += 2;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c80); }
          }
        }
      }
    }

    return s0;
  }

  function peg$parsepound() {
    var s0;

    if (input.substr(peg$currPos, 6).toLowerCase() === peg$c81) {
      s0 = input.substr(peg$currPos, 6);
      peg$currPos += 6;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c82); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 5).toLowerCase() === peg$c83) {
        s0 = input.substr(peg$currPos, 5);
        peg$currPos += 5;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c84); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 4).toLowerCase() === peg$c85) {
          s0 = input.substr(peg$currPos, 4);
          peg$currPos += 4;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c86); }
        }
        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 3).toLowerCase() === peg$c87) {
            s0 = input.substr(peg$currPos, 3);
            peg$currPos += 3;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c88); }
          }
          if (s0 === peg$FAILED) {
            if (input.substr(peg$currPos, 3).toLowerCase() === peg$c89) {
              s0 = input.substr(peg$currPos, 3);
              peg$currPos += 3;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c90); }
            }
            if (s0 === peg$FAILED) {
              if (input.substr(peg$currPos, 2).toLowerCase() === peg$c91) {
                s0 = input.substr(peg$currPos, 2);
                peg$currPos += 2;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c92); }
              }
              if (s0 === peg$FAILED) {
                if (input.substr(peg$currPos, 6).toLowerCase() === peg$c93) {
                  s0 = input.substr(peg$currPos, 6);
                  peg$currPos += 6;
                } else {
                  s0 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c94); }
                }
                if (s0 === peg$FAILED) {
                  if (input.substr(peg$currPos, 5).toLowerCase() === peg$c95) {
                    s0 = input.substr(peg$currPos, 5);
                    peg$currPos += 5;
                  } else {
                    s0 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c96); }
                  }
                }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parsequart() {
    var s0;

    if (input.substr(peg$currPos, 6).toLowerCase() === peg$c97) {
      s0 = input.substr(peg$currPos, 6);
      peg$currPos += 6;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c98); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 5).toLowerCase() === peg$c99) {
        s0 = input.substr(peg$currPos, 5);
        peg$currPos += 5;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c100); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 4).toLowerCase() === peg$c101) {
          s0 = input.substr(peg$currPos, 4);
          peg$currPos += 4;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c102); }
        }
        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 3).toLowerCase() === peg$c103) {
            s0 = input.substr(peg$currPos, 3);
            peg$currPos += 3;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c104); }
          }
          if (s0 === peg$FAILED) {
            if (input.substr(peg$currPos, 3).toLowerCase() === peg$c105) {
              s0 = input.substr(peg$currPos, 3);
              peg$currPos += 3;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c106); }
            }
            if (s0 === peg$FAILED) {
              if (input.substr(peg$currPos, 2).toLowerCase() === peg$c107) {
                s0 = input.substr(peg$currPos, 2);
                peg$currPos += 2;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c108); }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parsetablespoon() {
    var s0;

    if (input.substr(peg$currPos, 11).toLowerCase() === peg$c109) {
      s0 = input.substr(peg$currPos, 11);
      peg$currPos += 11;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c110); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 10).toLowerCase() === peg$c111) {
        s0 = input.substr(peg$currPos, 10);
        peg$currPos += 10;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c112); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 5).toLowerCase() === peg$c113) {
          s0 = input.substr(peg$currPos, 5);
          peg$currPos += 5;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c114); }
        }
        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 4).toLowerCase() === peg$c115) {
            s0 = input.substr(peg$currPos, 4);
            peg$currPos += 4;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c116); }
          }
          if (s0 === peg$FAILED) {
            if (input.substr(peg$currPos, 4).toLowerCase() === peg$c117) {
              s0 = input.substr(peg$currPos, 4);
              peg$currPos += 4;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c118); }
            }
            if (s0 === peg$FAILED) {
              if (input.substr(peg$currPos, 3).toLowerCase() === peg$c119) {
                s0 = input.substr(peg$currPos, 3);
                peg$currPos += 3;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c120); }
              }
              if (s0 === peg$FAILED) {
                if (input.substr(peg$currPos, 2) === peg$c121) {
                  s0 = peg$c121;
                  peg$currPos += 2;
                } else {
                  s0 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c122); }
                }
                if (s0 === peg$FAILED) {
                  if (input.charCodeAt(peg$currPos) === 84) {
                    s0 = peg$c123;
                    peg$currPos++;
                  } else {
                    s0 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c124); }
                  }
                  if (s0 === peg$FAILED) {
                    if (input.substr(peg$currPos, 7) === peg$c125) {
                      s0 = peg$c125;
                      peg$currPos += 7;
                    } else {
                      s0 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c126); }
                    }
                    if (s0 === peg$FAILED) {
                      if (input.substr(peg$currPos, 10) === peg$c127) {
                        s0 = peg$c127;
                        peg$currPos += 10;
                      } else {
                        s0 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c128); }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseteaspoon() {
    var s0;

    if (input.substr(peg$currPos, 9).toLowerCase() === peg$c129) {
      s0 = input.substr(peg$currPos, 9);
      peg$currPos += 9;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c130); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 8).toLowerCase() === peg$c131) {
        s0 = input.substr(peg$currPos, 8);
        peg$currPos += 8;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c132); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 4).toLowerCase() === peg$c133) {
          s0 = input.substr(peg$currPos, 4);
          peg$currPos += 4;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c134); }
        }
        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 3).toLowerCase() === peg$c135) {
            s0 = input.substr(peg$currPos, 3);
            peg$currPos += 3;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c136); }
          }
          if (s0 === peg$FAILED) {
            if (input.substr(peg$currPos, 2) === peg$c137) {
              s0 = peg$c137;
              peg$currPos += 2;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c138); }
            }
            if (s0 === peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 116) {
                s0 = peg$c139;
                peg$currPos++;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c140); }
              }
              if (s0 === peg$FAILED) {
                if (input.substr(peg$currPos, 7).toLowerCase() === peg$c141) {
                  s0 = input.substr(peg$currPos, 7);
                  peg$currPos += 7;
                } else {
                  s0 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c142); }
                }
                if (s0 === peg$FAILED) {
                  if (input.substr(peg$currPos, 9).toLowerCase() === peg$c143) {
                    s0 = input.substr(peg$currPos, 9);
                    peg$currPos += 9;
                  } else {
                    s0 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c144); }
                  }
                  if (s0 === peg$FAILED) {
                    if (input.substr(peg$currPos, 8).toLowerCase() === peg$c145) {
                      s0 = input.substr(peg$currPos, 8);
                      peg$currPos += 8;
                    } else {
                      s0 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c146); }
                    }
                    if (s0 === peg$FAILED) {
                      if (input.substr(peg$currPos, 7).toLowerCase() === peg$c147) {
                        s0 = input.substr(peg$currPos, 7);
                        peg$currPos += 7;
                      } else {
                        s0 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c148); }
                      }
                      if (s0 === peg$FAILED) {
                        if (input.substr(peg$currPos, 16).toLowerCase() === peg$c149) {
                          s0 = input.substr(peg$currPos, 16);
                          peg$currPos += 16;
                        } else {
                          s0 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c150); }
                        }
                        if (s0 === peg$FAILED) {
                          if (input.substr(peg$currPos, 15).toLowerCase() === peg$c151) {
                            s0 = input.substr(peg$currPos, 15);
                            peg$currPos += 15;
                          } else {
                            s0 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c152); }
                          }
                          if (s0 === peg$FAILED) {
                            if (input.substr(peg$currPos, 15).toLowerCase() === peg$c153) {
                              s0 = input.substr(peg$currPos, 15);
                              peg$currPos += 15;
                            } else {
                              s0 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c154); }
                            }
                            if (s0 === peg$FAILED) {
                              if (input.substr(peg$currPos, 14).toLowerCase() === peg$c155) {
                                s0 = input.substr(peg$currPos, 14);
                                peg$currPos += 14;
                              } else {
                                s0 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c156); }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parsemetric_unit() {
    var s0, s1;

    s0 = peg$currPos;
    s1 = peg$parsegram();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c157();
    }
    s0 = s1;
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parsekilogram();
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c158();
      }
      s0 = s1;
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parseliter();
        if (s1 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c159();
        }
        s0 = s1;
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          s1 = peg$parsedeciliter();
          if (s1 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c160();
          }
          s0 = s1;
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            s1 = peg$parsecentiliter();
            if (s1 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c161();
            }
            s0 = s1;
            if (s0 === peg$FAILED) {
              s0 = peg$currPos;
              s1 = peg$parsemilligram();
              if (s1 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c162();
              }
              s0 = s1;
              if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = peg$parsemilliliter();
                if (s1 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c163();
                }
                s0 = s1;
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parsegram() {
    var s0;

    if (input.substr(peg$currPos, 5).toLowerCase() === peg$c164) {
      s0 = input.substr(peg$currPos, 5);
      peg$currPos += 5;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c165); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 7).toLowerCase() === peg$c166) {
        s0 = input.substr(peg$currPos, 7);
        peg$currPos += 7;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c167); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 6).toLowerCase() === peg$c168) {
          s0 = input.substr(peg$currPos, 6);
          peg$currPos += 6;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c169); }
        }
        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 4).toLowerCase() === peg$c170) {
            s0 = input.substr(peg$currPos, 4);
            peg$currPos += 4;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c171); }
          }
          if (s0 === peg$FAILED) {
            if (input.substr(peg$currPos, 3).toLowerCase() === peg$c172) {
              s0 = input.substr(peg$currPos, 3);
              peg$currPos += 3;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c173); }
            }
            if (s0 === peg$FAILED) {
              if (input.substr(peg$currPos, 2).toLowerCase() === peg$c174) {
                s0 = input.substr(peg$currPos, 2);
                peg$currPos += 2;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c175); }
              }
              if (s0 === peg$FAILED) {
                if (input.substr(peg$currPos, 2).toLowerCase() === peg$c176) {
                  s0 = input.substr(peg$currPos, 2);
                  peg$currPos += 2;
                } else {
                  s0 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c177); }
                }
                if (s0 === peg$FAILED) {
                  if (input.substr(peg$currPos, 1).toLowerCase() === peg$c178) {
                    s0 = input.charAt(peg$currPos);
                    peg$currPos++;
                  } else {
                    s0 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c179); }
                  }
                }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parsekilogram() {
    var s0;

    if (input.substr(peg$currPos, 9).toLowerCase() === peg$c180) {
      s0 = input.substr(peg$currPos, 9);
      peg$currPos += 9;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c181); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 8).toLowerCase() === peg$c182) {
        s0 = input.substr(peg$currPos, 8);
        peg$currPos += 8;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c183); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 3).toLowerCase() === peg$c184) {
          s0 = input.substr(peg$currPos, 3);
          peg$currPos += 3;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c185); }
        }
        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 2).toLowerCase() === peg$c186) {
            s0 = input.substr(peg$currPos, 2);
            peg$currPos += 2;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c187); }
          }
          if (s0 === peg$FAILED) {
            if (input.substr(peg$currPos, 11).toLowerCase() === peg$c188) {
              s0 = input.substr(peg$currPos, 11);
              peg$currPos += 11;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c189); }
            }
            if (s0 === peg$FAILED) {
              if (input.substr(peg$currPos, 10).toLowerCase() === peg$c190) {
                s0 = input.substr(peg$currPos, 10);
                peg$currPos += 10;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c191); }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseliter() {
    var s0;

    if (input.substr(peg$currPos, 6).toLowerCase() === peg$c192) {
      s0 = input.substr(peg$currPos, 6);
      peg$currPos += 6;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c193); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 5).toLowerCase() === peg$c194) {
        s0 = input.substr(peg$currPos, 5);
        peg$currPos += 5;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c195); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 6).toLowerCase() === peg$c196) {
          s0 = input.substr(peg$currPos, 6);
          peg$currPos += 6;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c197); }
        }
        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 5).toLowerCase() === peg$c198) {
            s0 = input.substr(peg$currPos, 5);
            peg$currPos += 5;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c199); }
          }
          if (s0 === peg$FAILED) {
            if (input.substr(peg$currPos, 2).toLowerCase() === peg$c200) {
              s0 = input.substr(peg$currPos, 2);
              peg$currPos += 2;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c201); }
            }
            if (s0 === peg$FAILED) {
              if (input.substr(peg$currPos, 1).toLowerCase() === peg$c202) {
                s0 = input.charAt(peg$currPos);
                peg$currPos++;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c203); }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parsedeciliter() {
    var s0;

    if (input.substr(peg$currPos, 10).toLowerCase() === peg$c204) {
      s0 = input.substr(peg$currPos, 10);
      peg$currPos += 10;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c205); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 9).toLowerCase() === peg$c206) {
        s0 = input.substr(peg$currPos, 9);
        peg$currPos += 9;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c207); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 3).toLowerCase() === peg$c208) {
          s0 = input.substr(peg$currPos, 3);
          peg$currPos += 3;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c209); }
        }
        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 2).toLowerCase() === peg$c210) {
            s0 = input.substr(peg$currPos, 2);
            peg$currPos += 2;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c211); }
          }
          if (s0 === peg$FAILED) {
            if (input.substr(peg$currPos, 10).toLowerCase() === peg$c212) {
              s0 = input.substr(peg$currPos, 10);
              peg$currPos += 10;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c213); }
            }
            if (s0 === peg$FAILED) {
              if (input.substr(peg$currPos, 9).toLowerCase() === peg$c214) {
                s0 = input.substr(peg$currPos, 9);
                peg$currPos += 9;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c215); }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parsecentiliter() {
    var s0;

    if (input.substr(peg$currPos, 11).toLowerCase() === peg$c216) {
      s0 = input.substr(peg$currPos, 11);
      peg$currPos += 11;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c217); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 10).toLowerCase() === peg$c218) {
        s0 = input.substr(peg$currPos, 10);
        peg$currPos += 10;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c219); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 3).toLowerCase() === peg$c220) {
          s0 = input.substr(peg$currPos, 3);
          peg$currPos += 3;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c221); }
        }
        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 2).toLowerCase() === peg$c222) {
            s0 = input.substr(peg$currPos, 2);
            peg$currPos += 2;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c223); }
          }
          if (s0 === peg$FAILED) {
            if (input.substr(peg$currPos, 11).toLowerCase() === peg$c224) {
              s0 = input.substr(peg$currPos, 11);
              peg$currPos += 11;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c225); }
            }
            if (s0 === peg$FAILED) {
              if (input.substr(peg$currPos, 10).toLowerCase() === peg$c226) {
                s0 = input.substr(peg$currPos, 10);
                peg$currPos += 10;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c227); }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parsemilligram() {
    var s0;

    if (input.substr(peg$currPos, 10).toLowerCase() === peg$c228) {
      s0 = input.substr(peg$currPos, 10);
      peg$currPos += 10;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c229); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 9).toLowerCase() === peg$c230) {
        s0 = input.substr(peg$currPos, 9);
        peg$currPos += 9;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c231); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 3).toLowerCase() === peg$c232) {
          s0 = input.substr(peg$currPos, 3);
          peg$currPos += 3;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c233); }
        }
        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 2).toLowerCase() === peg$c234) {
            s0 = input.substr(peg$currPos, 2);
            peg$currPos += 2;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c235); }
          }
          if (s0 === peg$FAILED) {
            if (input.substr(peg$currPos, 12).toLowerCase() === peg$c236) {
              s0 = input.substr(peg$currPos, 12);
              peg$currPos += 12;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c237); }
            }
            if (s0 === peg$FAILED) {
              if (input.substr(peg$currPos, 11).toLowerCase() === peg$c238) {
                s0 = input.substr(peg$currPos, 11);
                peg$currPos += 11;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c239); }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parsemilliliter() {
    var s0;

    if (input.substr(peg$currPos, 11).toLowerCase() === peg$c240) {
      s0 = input.substr(peg$currPos, 11);
      peg$currPos += 11;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c241); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 10).toLowerCase() === peg$c242) {
        s0 = input.substr(peg$currPos, 10);
        peg$currPos += 10;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c243); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 3).toLowerCase() === peg$c244) {
          s0 = input.substr(peg$currPos, 3);
          peg$currPos += 3;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c245); }
        }
        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 2).toLowerCase() === peg$c246) {
            s0 = input.substr(peg$currPos, 2);
            peg$currPos += 2;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c247); }
          }
          if (s0 === peg$FAILED) {
            if (input.substr(peg$currPos, 11).toLowerCase() === peg$c248) {
              s0 = input.substr(peg$currPos, 11);
              peg$currPos += 11;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c249); }
            }
            if (s0 === peg$FAILED) {
              if (input.substr(peg$currPos, 10).toLowerCase() === peg$c250) {
                s0 = input.substr(peg$currPos, 10);
                peg$currPos += 10;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c251); }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseimprecise_unit() {
    var s0, s1;

    s0 = peg$currPos;
    s1 = peg$parsedash();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c30();
    }
    s0 = s1;
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parsehandful();
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c252();
      }
      s0 = s1;
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parsepinch();
        if (s1 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c253();
        }
        s0 = s1;
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          s1 = peg$parsetouch();
          if (s1 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c254();
          }
          s0 = s1;
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            s1 = peg$parsebox();
            if (s1 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c255();
            }
            s0 = s1;
            if (s0 === peg$FAILED) {
              s0 = peg$currPos;
              s1 = peg$parsetablet();
              if (s1 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c256();
              }
              s0 = s1;
              if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = peg$parsebit();
                if (s1 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c257();
                }
                s0 = s1;
                if (s0 === peg$FAILED) {
                  s0 = peg$currPos;
                  s1 = peg$parsepacket();
                  if (s1 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c258();
                  }
                  s0 = s1;
                }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parsebox() {
    var s0;

    if (input.substr(peg$currPos, 6).toLowerCase() === peg$c259) {
      s0 = input.substr(peg$currPos, 6);
      peg$currPos += 6;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c260); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 5).toLowerCase() === peg$c261) {
        s0 = input.substr(peg$currPos, 5);
        peg$currPos += 5;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c262); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 6).toLowerCase() === peg$c263) {
          s0 = input.substr(peg$currPos, 6);
          peg$currPos += 6;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c264); }
        }
        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 5).toLowerCase() === peg$c265) {
            s0 = input.substr(peg$currPos, 5);
            peg$currPos += 5;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c266); }
          }
          if (s0 === peg$FAILED) {
            if (input.substr(peg$currPos, 10).toLowerCase() === peg$c267) {
              s0 = input.substr(peg$currPos, 10);
              peg$currPos += 10;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c268); }
            }
            if (s0 === peg$FAILED) {
              if (input.substr(peg$currPos, 9).toLowerCase() === peg$c269) {
                s0 = input.substr(peg$currPos, 9);
                peg$currPos += 9;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c270); }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parsedash() {
    var s0;

    if (input.substr(peg$currPos, 6).toLowerCase() === peg$c271) {
      s0 = input.substr(peg$currPos, 6);
      peg$currPos += 6;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c272); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 4).toLowerCase() === peg$c273) {
        s0 = input.substr(peg$currPos, 4);
        peg$currPos += 4;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c274); }
      }
    }

    return s0;
  }

  function peg$parsehandful() {
    var s0;

    if (input.substr(peg$currPos, 8).toLowerCase() === peg$c275) {
      s0 = input.substr(peg$currPos, 8);
      peg$currPos += 8;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c276); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 7).toLowerCase() === peg$c277) {
        s0 = input.substr(peg$currPos, 7);
        peg$currPos += 7;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c278); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 8).toLowerCase() === peg$c279) {
          s0 = input.substr(peg$currPos, 8);
          peg$currPos += 8;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c280); }
        }
        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 7).toLowerCase() === peg$c281) {
            s0 = input.substr(peg$currPos, 7);
            peg$currPos += 7;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c282); }
          }
        }
      }
    }

    return s0;
  }

  function peg$parsetablet() {
    var s0;

    if (input.substr(peg$currPos, 7).toLowerCase() === peg$c283) {
      s0 = input.substr(peg$currPos, 7);
      peg$currPos += 7;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c284); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 9).toLowerCase() === peg$c285) {
        s0 = input.substr(peg$currPos, 9);
        peg$currPos += 9;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c286); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 8).toLowerCase() === peg$c287) {
          s0 = input.substr(peg$currPos, 8);
          peg$currPos += 8;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c288); }
        }
        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 6).toLowerCase() === peg$c289) {
            s0 = input.substr(peg$currPos, 6);
            peg$currPos += 6;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c290); }
          }
        }
      }
    }

    return s0;
  }

  function peg$parsebit() {
    var s0;

    if (input.substr(peg$currPos, 8).toLowerCase() === peg$c291) {
      s0 = input.substr(peg$currPos, 8);
      peg$currPos += 8;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c292); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 7).toLowerCase() === peg$c293) {
        s0 = input.substr(peg$currPos, 7);
        peg$currPos += 7;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c294); }
      }
    }

    return s0;
  }

  function peg$parsepacket() {
    var s0;

    if (input.substr(peg$currPos, 7).toLowerCase() === peg$c295) {
      s0 = input.substr(peg$currPos, 7);
      peg$currPos += 7;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c296); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 6).toLowerCase() === peg$c297) {
        s0 = input.substr(peg$currPos, 6);
        peg$currPos += 6;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c298); }
      }
    }

    return s0;
  }

  function peg$parsepinch() {
    var s0;

    if (input.substr(peg$currPos, 7).toLowerCase() === peg$c299) {
      s0 = input.substr(peg$currPos, 7);
      peg$currPos += 7;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c300); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 5).toLowerCase() === peg$c301) {
        s0 = input.substr(peg$currPos, 5);
        peg$currPos += 5;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c302); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 7).toLowerCase() === peg$c303) {
          s0 = input.substr(peg$currPos, 7);
          peg$currPos += 7;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c304); }
        }
        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 6).toLowerCase() === peg$c305) {
            s0 = input.substr(peg$currPos, 6);
            peg$currPos += 6;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c306); }
          }
        }
      }
    }

    return s0;
  }

  function peg$parsetouch() {
    var s0;

    if (input.substr(peg$currPos, 7).toLowerCase() === peg$c307) {
      s0 = input.substr(peg$currPos, 7);
      peg$currPos += 7;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c308); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 5).toLowerCase() === peg$c309) {
        s0 = input.substr(peg$currPos, 5);
        peg$currPos += 5;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c310); }
      }
    }

    return s0;
  }

  peg$result = peg$startRuleFunction();

  if (peg$result !== peg$FAILED && peg$currPos === input.length) {
    return peg$result;
  } else {
    if (peg$result !== peg$FAILED && peg$currPos < input.length) {
      peg$fail(peg$endExpectation());
    }

    throw peg$buildStructuredError(
      peg$maxFailExpected,
      peg$maxFailPos < input.length ? input.charAt(peg$maxFailPos) : null,
      peg$maxFailPos < input.length
        ? peg$computeLocation(peg$maxFailPos, peg$maxFailPos + 1)
        : peg$computeLocation(peg$maxFailPos, peg$maxFailPos)
    );
  }
}

module.exports = {
  SyntaxError: peg$SyntaxError,
  parse:       peg$parse
};
